@import "src/styles";

$component: "article-detail-renderer";

.#{$component} {
    padding-block-end: 6rem;

    @include for-size(tablet-portrait-up) {
        padding-block-end: 12rem;
    }

    &__body-wrapper {
        margin-block-start: 2.5rem;

        @include for-size(tablet-landscape-up) {
            display: grid;
            grid-template-columns: calc(100% / 1.5) calc(100% / 3);
            margin-block-start: 5rem;
        }
    }

    &__body {
        @include one-column-line-grid(#{$component}__body);

        padding-block: 0;

        @include for-size(tablet-portrait-up) {
            padding-inline-end: 2rem;
        }

        @include for-size(tablet-landscape-up) {
            padding-inline-end: 4rem;
            border-inline-end: none;
        }
    }

    &__aside {
        display: grid;
        grid-template-columns: 1fr;
        align-content: start;
        gap: 2.5rem;
        margin-block-start: 2.5rem;

        @include for-size(tablet-portrait-up) {
            grid-template-columns: repeat(2, 1fr);
            gap: 0;
        }

        @include for-size(tablet-landscape-up) {
            grid-template-columns: 1fr;
            gap: 2.5rem;
            margin-block-start: 0;
        }
    }

    &__aside-card-wrapper {
        padding-inline-end: 2rem;

        @include for-size(tablet-landscape-up) {
            padding-inline-end: 0;
        }
    }

    &__aside-card {
        width: 100%;
    }

    &__download-button {
        margin-block-start: 4rem;
        margin-block-end: 9rem;
    }
}
