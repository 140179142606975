@import "src/styles";

.download-card {
    padding-block-start: 2.5rem;

    &__title {
        @extend %heading-4;

        color: var(--c-white);
    }

    &__labeled-download-button {
        margin-block-start: 1.5rem;
        align-items: center;

        @include for-size(phone-only) {
            align-items: unset;
        }
    }
}
